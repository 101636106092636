import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent, RouterOutlet } from '@angular/router';
import { AuthService } from './services/auth.service';
import { ProductionViewService } from './services/production-view.service';
import { DocumentDataModel } from './models/document-data.model';
import { CommonService } from './services/common.service';
import { NgIf } from '@angular/common';
import { switchMap } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: true,
    imports: [NgIf, RouterOutlet],
})
export class AppComponent implements OnInit {
  title = 'angularproductionview';
  folderHash;
  loginSuccess = false;

  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private productionViewService: ProductionViewService,
    private commonService: CommonService
  ) {
    translate.addLangs(['en', 'nl', 'fr']);

    if (!this.cookieService.check('Language')) {
      this.cookieService.set('Language', 'en', { path: '/' });
      translate.setDefaultLang('en');
      translate.use('en');
    } else {
      let language = this.cookieService.get('Language');
      translate.setDefaultLang(language);
      translate.use(language);
    }
  }

  ngOnInit() {
    if (window.location.href.includes('?token')) {
      if (window.location.href.includes('tab')) {
        var hrefCopy = window.location.href.replace('?token', '&token');
        window.location.href = hrefCopy;
      } else {
        this.routerEvents();
      }
    } else {
      this.routerEvents();
    }
  }

  routerEvents() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          !this.cookieService.check('token') ||
          !this.auth.isAuthenticated()
        ) {
          let token = null;
          let domain = this.getDomain();
          let urlToken = this.route.snapshot.queryParamMap.get('token');
          this.folderHash = this.getFolderHash();

          //There is no cookie
          if (!this.cookieService.check('token')) {
            token = urlToken;
          }
          //There is cookie
          else {
            token = this.cookieService.get('token');
          }

          if (token != null && token != undefined) {
            this.auth.authorize(token).then((data) => {
              if (data.result == 'Success') {
                this.loginSuccess = true;

                //Set or update cookie
                this.setCookie(token);

                //Remove token from url
                this.removeToken();

                // Redirect to project page
                this.productionViewService
                  .getDocumentProject(this.folderHash)
                  .pipe(
                    switchMap(() =>
                      this.productionViewService.getDocumentData(
                        this.folderHash
                      )
                    )
                  )
                  .subscribe((document: DocumentDataModel) => {
                    this.commonService.writeNewDocumentData(document);
                    let project = document.project.toLowerCase();

                    if (project == 'invoice') {
                      project = document.area.toLowerCase();
                    }

                    this.router.navigate([project, this.folderHash]);
                  });
              } else {
                //Redirect to login
                this.auth.login(domain);
              }
            });
          } else {
            //Redirect to login
            this.auth.login(domain);
          }
        }
      }
    });
  }

  setCookie(token) {
    //Set an expiration of 1 hour; 1h/24h = 0.04166666666
    this.cookieService.set('token', token, {
      expires: 0.04166666666,
      path: '/',
    });
  }

  removeToken() {
    if (this.cookieService.check('token')) {
      this.router.navigate([], {
        queryParams: {
          token: null,
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  getDomain() {
    return window.location.href;
  }

  getFolderHash() {
    var hash = window.location.href.split('/')[3].split('?')[0];
    var tag = window.location.href.split('/')[4];

    if (tag) hash += '%2F' + tag;

    return hash;
  }
}
